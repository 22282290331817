import React from 'react';

const Nav = () => (
  <a href={'/'} className={'App-header-link'}>
    <div className="App-header-top">
      <h1>National Warm Bank</h1>
    </div>
  </a>
)

export default Nav;
