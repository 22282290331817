import React from 'react'
import Nav from '../../Nav';

const Home = () => (
  <div className="App">
    <header className="App-header">
      <Nav />
      <form className="App-header-search" action={'/search'}>
        <label className="App-header-label" htmlFor={'search'}>
          Enter full postcode
        </label>
        <div className="App-header-input-container">
          <input className="App-header-input" id={'search'} type={'text'} autoComplete={'off'} name={'postcode'} />
          <button className="App-header-input-button" type="submit">Search</button>
        </div>
      </form>
    </header>
  </div>
)



export default Home
