import React from 'react'

const Item = ({ isFirst, name, distance }) => {
  return (
    <article className={isFirst ? 'App-results-item first' : 'App-results-item'}>
      {isFirst && (
        <div className="ribbon ribbon-top-left">
          <span>closest</span>
        </div>
      )}
      <h1>{name}</h1>
      <ul>
        <li>Distance: <b>{distance}km</b></li>
        <li>Contact Email: <b>test@test.com</b></li>
        <li>Contact Phone: <b>0117 test test</b></li>
        <li>Open Days: <b>Saturday, Sunday</b></li>
        <li>Open Time: <b>8am till 4pm</b></li>
      </ul>
    </article>
  )
}

export default Item
