import React from 'react'
import useSWR from 'swr'

import Nav from '../../Nav';
import Item from '../../Item';
import { useSearchParams } from 'react-router-dom';

const fetcher = (url) => fetch(url).then((res) => res.json());

const Search = () => {
  const [ searchParams ] = useSearchParams()
  const postcode = searchParams.get('postcode')

  const { data, error } = useSWR(`https://ui5b1msdb6.execute-api.eu-west-1.amazonaws.com/dev/v1/locations?postcode=${postcode}`, fetcher)

  if (error) {
    return (
      <div className="App">
        <header className="App-header App-header-results">
          <Nav/>
        </header>
        <h1 className={'App-header-results-title'}>Something went wrong!</h1>
      </div>
    )
  }

  if (data?.status === 'fail') {
    return (
      <div className="App">
        <header className="App-header App-header-results">
          <Nav/>
        </header>
        <h1 className={'App-header-results-title'}>{data.data.message}</h1>
      </div>
    )
  }

  if (!data) {
    return (
      <div className="App">
        <header className="App-header App-header-results">
          <Nav/>
        </header>
        <h1 className={'App-header-results-title'}>Loading...</h1>
      </div>
    )
  }

  return (
    <div className="App">
      <header className="App-header App-header-results">
        <Nav/>
      </header>
      <h1 className={'App-header-results-title'}>Results for {postcode} within 100km</h1>
      <div className="App-results">
        {data.data.results.map(({ name, distance }, index) => <Item isFirst={index === 0} name={name} distance={distance} postcode={postcode} />)}
      </div>
    </div>
  )
}

export default Search
